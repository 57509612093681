body {
  font-size: 16px;
  font-family: Courier, sans-serif;
  margin-left: auto;
  margin-right: auto;
  background-color: #f6f6f6;
  width: 980px;
  height: 100%;
}

.twoColumn {
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  width: 100%;
  overflow: hidden;
  margin-right: 5px;
  padding: 5px;
  background-color: #eee;
}

.left {
  width: 30%;
}

.right {
  width: 70%;
}

/* Hide the nested list */
.nested {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.active {
  display: block;
}

ul {
  list-style-type: none;
  padding-left: 1.5em;
}

li {
  cursor: pointer;
}

li::before {
  content: "📄 ";
}

li.folder::before {
  content: "📂 ";
}

textarea {
  width: 100%;
  height: 100%;
  padding: 0;
}

#intro {
  margin-top: 3em;
}

#download {
  position: fixed;
  bottom: 3vmin;
  right: 3vmin;
  width: 18vmin;
  height: 18vmin;
  z-index: 10;
  text-align: center;
  font-size: 5vmin;
  color: #fff;
  border-radius: 10px;
  background: #bbb;
  opacity: 0.5;
  cursor: pointer;
}

#download:hover {
  opacity: 0.75;
}

#download span {
  font-size: 9vmin;
  font-weight: bold;
}
